$(document).on('turbolinks:load', function() {
  $('.js-trigger-feedback-onchange').on('change', function () {
    var feedback = $(this).data('feedback');
    var value    = $(this).is(':checked');
    var creature_id = $('#entity-metadata').data('id');

    $.ajax({
      type: "POST",
      url: '/api/feedback/api_receive',
      data: {
        feedback:    feedback,
        value:       value,
        entity_type: 'Creature',
        entity_id:   creature_id
      },
      success: function () {
        console.log('feedback for ' + feedback + ' received!');
      },
      dataType: 'json'
    });
  });

  $('.js-toggle-favorite').on('click', function () {
    var creature_id = $('#entity-metadata').data('id');
    var current_favorite = $(this).hasClass('js-current-favorite');

    var endpoint_url = "";
    if (current_favorite) {
      endpoint_url = '/api/content_favorite/unfavorite';

      $(this).removeClass('js-current-favorite');
      $(this).find('svg').removeClass('text-yellow-600');
    } else {
      endpoint_url = '/api/content_favorite/favorite'

      $(this).addClass('js-current-favorite');
      $(this).find('svg').addClass('text-yellow-600');
    }

    $.ajax({
      type: "POST",
      url: endpoint_url,
      data: {
        entity_type: 'Creature',
        entity_id:   creature_id
      },
      success: function () {
        console.log('favorite for CID' + creature_id + ' received!');
      },
      dataType: 'json'
    });
  });
});